<template>
  <div id="bb">
    <oSearch :boo.sync="SearchShow" ref="search"></oSearch>
    <div
      class="allP_left"
      ref="left"
      v-bind:style="{ height: height + 'px', top: top + 'px' }"
    >
      <swiper
        ref="Left_Swiper"
        :options="leftTitle"
        class="swiper-container-left"
        style="z-index: 997"
      >
        <swiper-slide ref="left_Pos_Wrap">
          <p
            @click="toHref(i, item.id, item.value)"
            ref="left_Pos"
            v-for="(item, i) in listData"
            :key="item.id"
            class="aaaa"
          >
            <b v-bind:class="{ act: i == index }">{{ item.value }}</b>
          </p>
        </swiper-slide>
      </swiper>
    </div>

    <div
      class="allP_main"
      v-bind:style="{ height: height + 'px', top: top + 'px' }"
    >
      <swiper
        ref="Right_Swiper"
        :options="rightText"
        class="swiper-container-right"
      >
        <swiper-slide>
          <!-- banner -->

          <swiper ref="mySwiper" :options="rightText">
            <swiper-slide>
              <div class="swiper-container-banner-ul">
                <swiper
                  ref="mySwiper"
                  :options="mainBanner"
                  class="swiper-container-banner"
                  v-if="banner.length > 0"
                >
                  <swiper-slide>
                    <a href="#">
                      <img
                        src="https://s5.mogucdn.com/mlcdn/c45406/200615_89378d4829hge79a7h7ke7ijl2l4e_368x640.jpg_600x800.v1cAC.81.webp"
                        alt=""
                        class="pro_banner_img"
                      />
                    </a>
                  </swiper-slide>
                </swiper>
              </div>
              <!-- 排序头部 -->
              <div class="product_sort flex-container">
                <div class="flex1 hover flex-container" @click="sort(1)">
                  <span>销量</span>
                  <img
                    src="@image/productlist/productList_middle.png"
                    v-if="proSort.sale == 0"
                  />
                  <img
                    src="@image/productlist/productList_top.png"
                    v-if="proSort.sale == 1"
                  />
                  <img
                    src="@image/productlist/productList_bottom.png"
                    v-if="proSort.sale == 2"
                  />
                </div>
                <div class="flex1 flex-container" @click="sort(2)">
                  <span>价格</span>
                  <img
                    src="@image/productlist/productList_middle.png"
                    v-if="proSort.price == 0"
                  />
                  <img
                    src="@image/productlist/productList_top.png"
                    v-if="proSort.price == 1"
                  />
                  <img
                    src="@image/productlist/productList_bottom.png"
                    v-if="proSort.price == 2"
                  />
                </div>
              </div>
              <!-- 产品列表 -->
              <div class="product_list_ul flex-container">
                <div
                  class="product_list_li"
                  v-for="(item, index) in productData"
                  :key="item.goods_id"
                  @click="goDetail(item.goods_id)"
                >
                  <div class="product_list_li-div">
                    <img
                      class="pro_img"
                      :src="item.goodsimage_full_url"
                      :alt="item.goods_name"
                    />
                    <!-- <img class='pro_jb' src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1037709337,3832572674&fm=26&gp=0.jpg" > -->
                  </div>
                  <p>{{ item.goods_name }}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { Toast } from "vant";
import oSearch from "@view/home/view/goodsSearch/setup.vue";

import merge from "webpack-merge";
import { post } from "@get/http";
// import HomeCommonSearch from "../common/HomeCommonSearch2";
export default {
  props: ["iconHeight"],
  components: {
    Swiper,
    SwiperSlide,
    oSearch,
    // HomeCommonSearch,
  },
  created() {
    if (this.$route.query.store_id) {
      this.store_id = this.$route.query.store_id;
    }
  },
  data: () => ({
    leftTitle: {
      direction: "vertical",
      slidesPerView: "auto",
      freeMode: true,
      mousewheel: true,
      resistanceRatio: 0,
    },
    SearchShow: false,
    height: "100%",
    top: "0",
    productData: [], //产品数据
    listData: [], //二级分类数据
    index: 0,
    proSort: {
      // 升序降序判断数据
      price: 0,
      sale: 0,
    },
    thisListData: -1, //当前点击的list
    thisSort: 5, //当前的排序
    banner: [],
    thisDom: undefined,
    initToIndex: -1,
    store_id: 2,
    right: {
      freeMode: true,
      mousewheel: true,
      resistanceRatio: 0,
      direction: "vertical",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    },
    rightText: {
      freeMode: true,
      mousewheel: true,
      resistanceRatio: 0,
      direction: "vertical",
      nested: true,
      slidesPerView: "auto",
      freeMode: true,
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      on: {
        touchEnd: function() {
          if (this.translate > 50) {
            // console.log("向下拉");
          }
          if (this.snapGrid[1] * -1 > this.translate + 70) {
            // console.log("向上拉");
          }
        },
      },
    },
    mainBanner: {
      loop: true,
      speed: 300,
      autoplay: {
        delay: 3000,
      },
    },
  }),
  computed: {
    swiper() {
      return this.$refs.Left_Swiper.$swiper;
    },
  },
  mounted() {
    // 设置高度 （修正滑动区域的高度问题）
    this.top = this.$refs.search.$el.offsetHeight;
    this.height = this.$refs.left.offsetHeight - this.top;
    // 修正后执行数据自加载
    this.getGoodsclassList();
  },
  updated() {
    // 左侧dom 数据查询并且插入
    this.thisDom = this.$refs.left_Pos;
  },
  watch: {
    thisListData() {
      this.getGoodsList(this.thisListData);
    },
    thisSort() {
      this.getGoodsList(this.thisListData, this.thisSort);
    },
    thisDom() {
      if (this.thisDom != undefined && this.initToIndex != -1) {
        this.thisDom[this.initToIndex].click();
      }
    },
  },
  methods: {
    sort(val) {
      // 价格降序是2  升序 1,2   销量降序是 3  销量升序是 1
      if (val == 1) {
        this.proSort.sale++;
        this.proSort.price = 0;
        if (this.proSort.sale == 1) {
          // 销售升序
          this.thisSort = 1;
        } else if (this.proSort.sale == 2) {
          // 销售降序
          this.thisSort = 3;
        } else if (this.proSort.sale >= 3) {
          this.proSort.sale = 0;
          this.thisSort = 5;
        }
      } else {
        this.proSort.price++;
        this.proSort.sale = 0;
        if (this.proSort.price == 1) {
          // 价格升序
          this.thisSort = 2;
        } else if (this.proSort.price == 2) {
          // 加个降序
          this.thisSort = 2;
          this.getGoodsList(this.thisListData, this.thisSort);
        } else if (this.proSort.price >= 3) {
          this.proSort.price = 0;
          this.thisSort = 5;
        }
      }
    },
    async getGoodsclassList() {
      let res = await post("/Store/store_goods_class", {
        data: {
          store_id: process.env.VUE_APP_STORE,
        },
      });
      // 获取店铺分类
      this.listData = res.store_goods_class;
      this.listData.unshift({
        children: [],
        id: 0,
        value: "全部分类",
      });
      // this.getGoodsList(res.result.store_goods_class[0].id);
      if (this.$route.query?.list_id != undefined) {
        for (var variable in this.listData) {
          if (this.listData.hasOwnProperty(variable)) {
            if (this.listData[variable].value == this.$route.query.list_id) {
              this.initToIndex = Number(variable);
              break;
            }
          }
        }
      } else {
        this.thisListData = 0;
      }
    },
    goDetail(goods_id) {
      this.$router.push({
        name: "HomeGoodsdetail",
        query: { goods_id: goods_id },
      });
    },
    toHref(i, id, txt) {
      // 运动
      if (i != this.index) {
        let dom = this.$refs.left_Pos[i];
        let Total_Middle_Pos = this.height / 2; //中间的距离
        let el_Height = dom.offsetHeight / 2;
        let doc_Top = dom.offsetTop; //元素距离视口的距离
        // let el_Top              = this.$refs.left_Pos[i].getBoundingClientRect().top            ;      //元素距离视口的距离
        // let el_Top              = this.$refs.left_Pos[i].getBoundingClientRect().top            ;     //元素距离视口的距离
        // let Total_Slid_Pos      = this.$refs.left_Pos_Wrap.$el.offsetHeight - this.height       ;     //总共可滑动的高度距离
        this.index = i;
        this.swiper.translateTo(
          (doc_Top - Total_Middle_Pos + el_Height) * -1,
          200,
          true,
          false
        ); //运动
      }

      // 刷新链接
      this.$router.push({
        query: merge(this.$route.query, {
          list_id: txt,
        }),
      });

      this.thisListData = id;
    },
    async getGoodsList(id, sort = 5) {
      let { goods_list } = await post("/lw.Store/store_goods", {
        data: {
          page: 1, //页数
          storegc_id: id, // 二级分类ID
          keyword: "",
          store_id: process.env.VUE_APP_STORE, //店铺ID
          sort_order: this.proSort.price == 1 ? "1" : "", //  按低到高排序为1
          sort_key: sort, //  按什么排序
          //5是默认     价格降序是2  升序 1,2   销量降序是 3  销量升序是 1
        },
      });
      this.productData = goods_list;
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  position: relative;
  height: 100%;
}
body {
  /* background: #eee; */
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.swiper-container {
  margin: 0;
  width: 100%;
  height: 100%;
}

.swiper-container2 .swiper-slide {
  height: auto;
}
@function rem($px) {
  @return $px / (75) * 1rem;
}
// @function rem($px) {
//   @return (($px / 20)/2) * 1rem;
// }

@function bai($px, $num: 7.5) {
  @return $px / $num * 1%;
}
@function bai2($px) {
  @return $px / 13.34 * 1%;
}

@function bai3($px) {
  @return $px / 12.06 * 1%;
}

@function bai4($px, $num) {
  @return $px / $num * 1%;
}

@mixin ell {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ell2($row: 2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  line-clamp: $row;
  -webkit-box-orient: vertical;
}

/*flex*/
.flex-container {
  display: -moz-box;
  /* Firefox */
  display: -ms-flexbox;
  /* IE10 */
  display: -webkit-box;
  /* Safari */
  display: -webkit-flex;
  /* Chrome, WebKit */
  // display: box;
  display: flexbox;
  display: flex;
}

.flex1 {
  -webkit-box-flex: 1;
  /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1;
  /* OLD - Firefox 19- */
  -webkit-flex: 1;
  /* Chrome */
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

// 左边
.swiper-container-left {
  background: #fff;

  // margin: 0!important;
  overflow: hidden;
  width: 100%;
  border-right: 1px solid #ebebeb;
  // height: bai3(816);
  height: 100%;
  .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    p {
      width: 100%;
      height: rem(120);
      line-height: rem(120);
      color: #333333;
      font-size: rem(28);
      position: relative;
      @include ell;
      b {
        display: block;

        text-align: center;
        @include ell;
      }
    }
    .act {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      color: #fb803f;
      // text-align: center;
      width: 100%;
      height: rem(40);
      font-size: rem(32);
      line-height: rem(40);
      border-left: rem(4) solid #fb803f;
      box-sizing: border-box;
    }
  }
}

// <!-- 中间位置内容 -->

.allP_main,
.allP_left {
  position: fixed !important;
  top: bai3(0);
  left: 0;
  height: 100%;
  width: 100%;
}
.allP_left {
  width: bai(160);
  z-index: 998;
}

// 右侧
.swiper-container-right {
  width: bai(590);
  margin-left: bai(160);

  height: 100%;
  overflow: hidden;
  .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

// banner
.swiper-container-banner-ul {
  // position: fixed;
  // z-index: 999999;
  // top:0;
  // left:0;
  // width: bai(560);
  width: 100%;
  // height: rem(290);
  // margin: 0  bai4(16,5.9);
  // margin-left: bai(176);
  box-sizing: border-box;
  overflow: hidden;
  // padding-bottom: rem(31);
  // .swiper-pagination-bullet{
  //   background: #b2b2b2;
  //   width: rem(10);height: rem(10);margin: 0 rem(4)!important;
  // }
  // .swiper-pagination-bullet-active{
  //   background: #fb803f;
  // }
  // .Sp-banner{
  //   z-index: 99999;
  //   // bottom: 24px;
  // }
  .swiper-container-banner {
    position: relative;
    padding-top: rem(16);
    height: 100%;
    // margin-top: rem(16);
    width: 100%;
    // height: bai(240,2.4);
    // box-sizing: border-box;
    // margin-bottom: rem(30);
    // margin-left: rem(6);
    .swiper-slide-active {
      // margin-left: rem(26)!important;
    }
    .swiper-slide {
      box-sizing: border-box;
      // text-align: center;
      // font-size: 18px;
      // background: #fff;
      width: bai(480);
      height: 100%;
      // margin-left: rem(20);
      /* Center slide text vertically */
      // display: -webkit-box;
      // display: -ms-flexbox;
      // display: -webkit-flex;
      // display: flex;
      // -webkit-box-pack: center;
      // -ms-flex-pack: center;
      // -webkit-justify-content: center;
      // justify-content: center;
      // -webkit-box-align: center;
      // -ms-flex-align: center;
      // -webkit-align-items: center;
      // align-items: center;6
      a {
        display: block;
        width: 100%;
        // height: rem(240);
        // border-radius: rem(10);
        box-sizing: border-box;
        padding: 0 bai4(16, 5.9);
      }
      img {
        display: block;
        width: 100%;
        height: rem(600);
        border-radius: rem(10);
        box-sizing: border-box;
      }
    }
  }
}

// 排序
.product_sort {
  justify-content: center;
  .flex1 {
    height: rem(100);
    // line-height: rem(100);
    // text-align: center;
    font-size: rem(28);
    color: #666666;
    justify-content: center;
    align-items: center;
    img {
      width: rem(15);
      height: rem(26);
      margin-left: rem(12);
    }
  }
  .hover {
    color: #333333;
  }
}

.product_list_ul {
  // justify-content: center;
  flex-wrap: wrap;
  width: rem(442);
  margin: 0 auto;
}
.product_list_li {
  margin-bottom: rem(60);

  .product_list_li-div {
    position: relative;
    width: rem(200);
    height: rem(200);
    .pro_img,
    .pro_jb {
      display: block;
      width: rem(200);
      height: rem(200);
    }
    .pro_jb {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  p {
    width: rem(200);
    padding-top: rem(16);
    text-align: center;
    color: #333333;
    font-size: rem(24);
    @include ell2;
  }
  &:nth-of-type(2n-1) {
    margin-right: rem(40);
  }
}
</style>
